import logo from "../at-infor-logo1.png";
import logotipo1 from "../img/new-home/at-infor-logotipo1.png";
import "../App.css";
import ContactForm from "./contact-form";
import wppLogo from "../img/new-home/whatsapp-glyph-black.svg";
import { Component } from "react";


const direccion = (
  <p>
    <a
      className="direccion"
      alt="Ubicacion en Google Maps"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.google.com/maps/place/Fragata+Hercules+596,+Puerto+Madryn,+Chubut/@-42.755275,-65.0497661,17z/data=!3m1!4b1!4m5!3m4!1s0xbe024a7f08b2b699:0x72e867d8f7d2217e!8m2!3d-42.755275!4d-65.0475774"
    >
      Fragata Hercules 596, Puerto Madryn, Chubut
    </a>
  </p>
);

const contacto = (
  <div>
    <p className="min-m-top">
      {" "}
      Email:
      <span>
        <a
          className="link-contacto"
          href="mailto:contacto@at-informatica.com.ar"
        >
          {" "}
          contacto@at-informatica.com.ar
        </a>
      </span>
    </p>
  </div>
);

const telefono = (
  <div>
    <p>
      Tel: <a className="link-contacto"> (0280) 4456800 </a>{" "}
    </p>
    <p className="pWhps">
      {" "}
      WhatsApp
      <a
        className="link-contacto whatsapp"
        target="_blank"
        rel="noopener noreferrer"
        alt="Whats App"
        href="https://wa.link/cej7v6"
      >
        {" "}
        <img className="whatsAppIcon" src={wppLogo} /> +5492804555735{" "}
      </a>{" "}
    </p>
  </div>
);

function FormBox(props) {
  if (props.show) {
    return null;
  } else {
    return (
      <div className="contact-form-div" id="contactForm">
        <ContactForm />
      </div>
    );
  }
}

class Home_at extends Component {
  constructor(props) {
    super(props);
    this.state = { showForm: true };
    this.handleOpenForm = this.handleOpenForm.bind(this);
  }

  handleOpenForm() {
    this.setState((state) => ({
      showForm: !state.showForm,
    }));
    console.log("funca " + this.state.showForm);
  }

  render() {
    return (
      <div className="App">
        <div className="App-content">
          <header className="App-header">
            <div className="logos">
              <img src={logo} className="home-logo" alt="logo" />
              <img src={logotipo1} className="home-logotipo" alt="logotipo" />
            </div>
            <div className="informacion">
              <div className="inf-esp">
                <p> Estamos en: </p>
                {direccion}
              </div>

              <div className="inf-esp">
                <p>Comunicate con nosotros al: </p>
                {telefono}
                {contacto}
              </div>
              <div className="social">
              <p>
                Nuestras redes
              </p>
              <div className="social-links">
                <a href="https://www.instagram.com/at.informatica.madryn/">
                  <i class="fa-brands fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/AT-Inform%C3%A1tica-106180652066776">
                  <i class="fa-brands fa-facebook"></i>
                </a>
                  
              </div>
              </div>
            </div>
          </header>
        </div>
            
      </div>
      

    );
  }
}
export default Home_at;
