import React, { Component, useState } from "react";
import sendingSvg from "../img/new-home/rings.svg";
import swal from 'sweetalert';


function SendingRing(props) {
    if(props.sending){
        return <img src={sendingSvg} className="sending"/>
          
    }
    else {
        return null
    }
}

class ContactForm extends Component {
    
    constructor (props) {
        super(props)   
        this.state = {
                values: {
                    nombre: '', 
                    email: '', 
                    telefono: '',
                    celular: '',
                    mensaje: ''
                },
                validations: {
                    nombre: '', 
                    email: '',
                    telefono:'',
                    celular:'', 
                    mensaje: ''
                },
                status: 'Submit',
                sending: false,
            }
        
    }
   
    

    setStatus = (newStatus) => {
        this.setState({ status : newStatus}) 
    }

    handleChange = (e) => {
        const { name, value} = e.target;
        this.setState({
            values: {
                ...this.state.values,
                [name] : value
            }
        })
    };

    validateAll = () => {
        const { nombre, email, telefono, celular, mensaje} = 
        this.state.values;
        const validations = {nombre: '', email: '', telefono:'',celular: '', mensaje:''};
        let isValid = true;

        if(!nombre) {
            validations.nombre = '*Nos falta tu nombre';
            isValid = false;
        }
        
        
        if(!email) {
            validations.email = '*Nos falta tu email';
            isValid = false;
        }

        if(!telefono && !celular) {
            validations.telefono = '*Nos falta tu teléfono o celular';
            validations.celular = '*Nos falta tu teléfono o celular';
            isValid = false;
        }

        
        if(!mensaje) {
            validations.mensaje = '*Nos falta tu mensaje';
            isValid = false;
        }
        
        if(!isValid) {
            this.setState({validations})
        }
        
        return isValid
    }

    validateOne = (e) => {
        const {name} = e.target;
        const value = this.state.values[name]
        let message = ''

        if (!value) {
            message = `*Nos falta tu ${name} `
        }

        this.setState({
            validations: {
                ...this.state.validations,
                [name]: message
            }
        })
    }

   

    handleSubmit = async (event) => {

        event.preventDefault();
        const isValid = this.validateAll();

        
  
        
        if(!isValid) {
            return false
        }
        this.setStatus("Sending...")
        this.setState({sending: true})
        let response = await fetch("http://localhost:5000/contact", {
            method: "POST",
            headers:{
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(this.state.values)
        })
        this.setStatus("Submit")
        this.setState({sending: false})


        const result = await response.json()
     
        console.error(response)
        console.error(result)

        if(result.status == "Tu mensaje se envió correctamente :)") {
            swal({
                title: "Mensaje enviado!",
                text: "Tu mensaje fue envio correctamente y te enviamos una copia a tu Email",
                icon: "success",
                button: "Listo",
            });
        }
        else {
            swal({
                title: "Algo salió mal",
                text: "El mensaje no fue enviado, pero podes probar con otras opciones de contacto",
                icon: "error",
                button: "Cerrar",
              });
        }

    };


    render(){
    const { nombre, email, telefono, celular, mensaje } = this.state.values
    const { 
      nombre: nameVal, 
      email: emailVal, 
      telefono: telVal,
      celular: celVal,
      mensaje: mensajeVal 
    } = this.state.validations

        return (
                <div className="form-component">
                    <div className="textForm">
                        <p>Dejanos tu mensaje, <br/> nos comunicaremos a la brevedad.
                        </p>
                    </div>
                <form onSubmit={this.handleSubmit}>
                    <label htmlFor="nombre">Nombre </label>
                    <br/>
                    <input 
                        name="nombre" 
                        type="text" 
                        placeholder="Ingresa tu nombre" 
                        className="input-looks1"
                        onChange={this.handleChange}
                        onBlur={this.validateOne}
                    />
                     <div className="valError">{nameVal}</div>
                    <br/>
                    <label htmlFor="email">Correo Electronico </label>
                    <br/>
                    <input 
                        name="email" 
                        type="email" 
                        placeholder="ejemplo@mail.com" 
                        className="input-looks1"
                        onChange={this.handleChange}
                        onBlur={this.validateOne}
                    />
                    <div className="valError">{emailVal}</div>
                    <br/>
                    <label htmlFor="telefono">Teléfono fijo</label>
                    <br/>
                    <input 
                        name="telefono" 
                        type="number" 
                        placeholder="Tu teléfono fijo" 
                        className="input-looks1"
                        onChange={this.handleChange}
                        
                    />
                    <div className="valError">{telVal}</div>
                    <br/>
                    <label htmlFor="celular">Celular </label>
                    <br/>
                    <input 
                        name="celular" 
                        type="number" 
                        placeholder="Tu celular" 
                        className="input-looks1"
                        onChange={this.handleChange}
                        
                    />
                    <div className="valError">{celVal}</div>
                    <br/>
                    <label htmlFor="mensaje">Mensaje </label> <br/>
                    <textarea
                        name="mensaje" 
                        type="text" 
                        placeholder="Detalle su problema aquí" 
                        className="descrip-input input-looks1"
                        onChange={this.handleChange}
                        onBlur={this.validateOne}
                    > </textarea>
                    <div  className="valError">{mensajeVal}</div> 
                    <div className="cont-send"> 
                    <button
                    className="boton-gen"
                    type="submit">
                        Enviar
                    </button>
                    <SendingRing className="sendingRing" sending={this.state.sending}/>

                    </div>
                    {/* <div>    
                        <pre>{JSON.stringify(this.state,null,2)}</pre>
                    </div> */}
                </form>
            </div>
            )
    }
    
  
};


export default ContactForm;